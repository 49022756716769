import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "client-integration-guide"
    }}>{`Client integration guide`}</h1>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#authentication"
        }}><strong parentName="a">{`Authentication`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#getting-personalised-offers"
        }}><strong parentName="a">{`Getting personalised offers`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#getting-current-status-of-a-customer"
        }}><strong parentName="a">{`Getting current status`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#posting-events-via-http"
        }}><strong parentName="a">{`Posting events via HTTP`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#api-docs"
        }}><strong parentName="a">{`Api docs`}</strong></a></li>
    </ul>
    <br />
    <hr></hr>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`In order to integrate with the Personalisation API, you need to supply a OAuth2 bearer token in the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header of the request.
Read more about authentication `}<a parentName="p" {...{
        "href": "/pages-intro-authentication#credentials"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "getting-personalised-offers"
    }}>{`Getting personalised offers`}</h2>
    <p>{`To get personalised offers, you don't integrate with personalisation directly, you instead supply metadata on the travellers when using the offers api.
See offers guide `}<a parentName="p" {...{
        "href": "/pages-offers-docs-search-guide-v2#search-for-personalised-offers"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "getting-current-status-of-a-customer"
    }}>{`Getting current status of a customer`}</h2>
    <p>{`To get the status of a customer to show in your client, use`}</p>
    <Swagger url="https://api.entur.io/personalisation/api-docs/client" fallback="https://petstore.swagger.io/?url=https://api.dev.entur.io/personalisation/api-docs/client" showBaseUrl={false} operationIds={["queryCurrentLevelSummaries"]} shouldGroupEndpointsByTags={false} mdxType="Swagger" />
    <p>{`This endpoint returns the status of the customer in all the programs they are a member of. If you are interested in a
specific program, you can use the `}<inlineCode parentName="p">{`programReference`}</inlineCode>{` field in the query to only get the status for that program. The
api will still return an array. If the customer is not a member of the program, the array will be empty, and if they
are a member, the array will have one element.`}</p>
    <h2 {...{
      "id": "posting-events-via-http"
    }}>{`Posting events via HTTP`}</h2>
    <p>{`Usually, events in personalisation are triggered from Kafka messages that are produced for example when a sale is made, or a ticket is activated.
However, programs may also be configured to react to events coming from HTTP calls to the service.
These events can be used if the program needs to do something as a result of a manual action, such as a member manually
joining a program. If the program you are integrating with has configured any such triggers, you may trigger them with
the following endpoint:`}</p>
    <Swagger url="https://api.entur.io/personalisation/api-docs/client" fallback="https://petstore.swagger.io/?url=https://api.dev.entur.io/personalisation/api-docs/client" showBaseUrl={false} operationIds={["postEvent"]} shouldGroupEndpointsByTags={false} mdxType="Swagger" />
    <h2 {...{
      "id": "api-reference"
    }}>{`API reference`}</h2>
    <p>{`All endpoints available for use in client integration with Personalisation are listed here: `}<a parentName="p" {...{
        "href": "/pages-personalisation-docs-personalisation-client-api"
      }}>{`Client API`}</a>{`.
Important changes to the API are listed here: `}<a parentName="p" {...{
        "href": "/personalisation_client_changelog"
      }}>{`Client API Changelog`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      